import config from '@/_config';

export default {
  company_level: [0, 30],
  work_days: [5],
  billings: null,
  daily_rate: [config.company.dailyRate.min, config.company.dailyRate.max],
  budget: [config.company.budgetRate.min, config.company.budgetRate.max],
  end_months: [6],
  attachments: [],
  nda_attachment: [],
  category_ids: [],
  sub_categories: [],
  skills: [],
  optional_skills: [],
  personal_skills: [],
  salary_confidentiality: true,
  salary: [config.company.salary.min, config.company.salary.max],
  individual_certification_skills: [],
  profile_company_value_skills: [],
  ...(config.mission.showTools && { tools: [], company_tools: [] }),
};
