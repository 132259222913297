import { ReactComponent as Zip } from '@/images/zip.svg';
import { ReactComponent as Briefcase } from '@/images/briefcase.svg';

export default [
  {
    label: 'fields.missions',
    value: 'missions',
    id: 'missions',
    icon: Zip,
    'data-testid': 'missions',
  },
  {
    label: 'fields.fulltime_contract',
    value: 'fulltime_contract',
    id: 'fulltime_contract',
    icon: Briefcase,
    'data-testid': 'fulltime_contract',
  },
];
