import React from 'react';
import { func } from 'prop-types';
import { RequiredField, RfJob, RfSelect } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import { length, presence } from '@/helpers/validation';
import { getlimitedList, isLimitedlistActived } from '@/helpers/limitedList';
import { get } from 'lodash';
import { CLIENT } from '@/constants/userKind';

const getComponent = (currentUser) => {
  // define default field if is defined like limited list or simple job field
  const defaultField = isLimitedlistActived('job_name') ? RfSelect : RfJob;

  // if user kind is client, file is simple job field for mapo, else is default field
  return get(currentUser, 'kind') === CLIENT ? RfJob : defaultField;
};

function JobSection({ t, currentUser }) {
  const component = getComponent(currentUser);

  return (
    <FormSection title={t('company.modal.modalities.job.title')}>
      <RequiredField
        component={component}
        name="job_name"
        inputProps={{
          label: t('company.modal.modalities.job.label'),
        }}
        className="tablet--one-whole"
        {...(isLimitedlistActived('job_name') && component === RfSelect
          ? {
              placeholder: t('postSignup.job_name.list.placeholder'),
              options: getlimitedList(t, 'job_name', true)(),
              validate: [presence()],
            }
          : { validate: [length({ max: 50 })] })}
      />
    </FormSection>
  );
}

JobSection.propTypes = {
  t: func.isRequired,
};

export default JobSection;
