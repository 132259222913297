import {
  Aeronautics,
  AssociationsFoundations,
  Automative,
  BankInsurance,
  BioEngineering,
  Defense,
  Digital,
  Distribution,
  Energy,
  FoodBeverage,
  Industry,
  Infrastructure,
  PropertyHotelerie,
  Services,
  SI,
  Telecoms,
  Transportation,
} from './sectors_default';

export default [
  Aeronautics,
  AssociationsFoundations,
  Automative,
  BankInsurance,
  BioEngineering,
  Defense,
  Digital,
  Distribution,
  Energy,
  FoodBeverage,
  Industry,
  Infrastructure,
  PropertyHotelerie,
  Services,
  SI,
  Telecoms,
  Transportation,
];
